.donate {
  &__tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 120px;
    background: #f6f6f6;
    border: 1px solid #0a0a0b;
    border-radius: 12px;
    padding: 24px;
    margin-bottom: 40px;
  }
  &__tabs-control {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    color: #868686;
    transition: 0.3s;
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    &:hover,
    &.active {
      color: #0a0a0b;
    }
  }
  &__tabs-content {
    display: none;
    &.active {
      display: block;
    }
  }
  &__footer {
    margin-top: 56px;
    display: flex;
    justify-content: flex-end;
  }
  &__text {
    margin-top: 56px;
    p {
      font-weight: 500;
      font-size: 24px;
      line-height: 190%;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: #0a0a0b;
    }
  }
}

.donate-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.donate-card {
  background: #f6f6f6;
  border: 1px solid #0a0a0b;
  border-radius: 16px;
  padding: 32px 32px 24px;
  position: relative;
  &__bg {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.h40 {
    padding-bottom: 80%;
  }
  &.white {
    background-color: #fff;
  }
  &.w100 {
    grid-column-start: 1;
    grid-column-end: -1;
    padding: 40px 48px 40px 32px;
    p {
      font-weight: 500;
      font-size: 18px;
      line-height: 180%;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: #0a0a0b;
    }
  }
  &.height2 {
    grid-row-start: 1;
    grid-row-end: 3;
    p {
      font-size: 21px;
      line-height: 173%;
      letter-spacing: 0.01em;
      color: #0a0a0b;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
  &__label {
    position: absolute;
    top: 40px;
    right: 32px;
    font-weight: 600;
    font-size: 15.2018px;
    line-height: 145.5%;
    letter-spacing: 0.01em;
    color: #000000;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &__title {
    font-weight: 600;
    font-size: 30px;
    line-height: 145.5%;
    letter-spacing: 0.01em;
    color: #000000;
    max-width: 350px;
    margin-bottom: 16px;
    span {
      border-radius: 24px;
      padding: 0 12px;
      display: inline-flex;
      align-items: center;
      &.yellow {
        background: #f4e27f;
      }
      &.green {
        background: #c9fec4;
      }
      &.purpure {
        background: #ccbaff;
      }
    }
  }
  &__text {
    font-size: 21px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: #000000;
    max-width: 400px;
  }
}

@media (max-width: 1200px) {
  .donate {
    &__text {
      p {
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 1199px) {
  .donate-card {
    &__label {
      font-size: 14px;
      right: 20px;
    }
    &__title {
      font-size: 24px;
    }
    &__text {
      font-size: 18px;
    }
    &.height2 {
      p {
        font-size: 18px;
      }
    }
    &.w100 {
      p {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 991px) {
  .donate {
    &__tabs {
      gap: 35px;
    }
    &__text {
      margin-top: 32px;
      p {
        font-size: 16px;
      }
    }
    &__footer {
      margin-top: 32px;
    }
  }

  .donate-card {
    &.height2 {
      grid-row-start: auto;
      grid-row-end: auto;
      grid-column-start: 1;
      grid-column-end: -1;
      p {
        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }
    }
    &__label {
      top: 12px;
      font-size: 12px;
      svg {
        width: 14px;
      }
    }
  }
}

@media (max-width: 767px) {
  .donate {
    &__tabs {
      margin-bottom: 8px;
      border: none;
      overflow-x: auto;
      justify-content: flex-start;
      padding: 0;
      gap: 0px;
      margin-left: -24px;
      margin-right: -24px;
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 8px;
    }
    &__tabs-control {
      font-size: 16px;
      flex-shrink: 0;
      &:not(:last-child) {
        margin-right: 12px;
      }
    }
  }
  .donate-cards {
    grid-template-columns: 1fr;
    gap: 12px;
  }
  .donate-card {
    padding: 24px;
    &.height2 p {
      font-size: 16px;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    &.w100 {
      padding: 24px;
    }
    &__title {
      font-size: 20px;
    }
    &__text {
      font-size: 16px;
    }
    &:nth-child(1) {
      order: 1;
    }
    &:nth-child(2) {
      order: 2;
    }
    &:nth-child(3) {
      order: 3;
    }
    &:nth-child(4) {
      order: 5;
    }
    &:nth-child(5) {
      order: 4;
    }
    &:nth-child(6) {
      order: 6;
    }
    &.h40 {
      order: 7;
    }
  }
}

@media (max-width: 1199px) {
  .donate-card {
    &.h40 {
      padding-bottom: 0px;
    }
    &__bg {
      position: relative;
      bottom: 0;
      left: 0;
      transform: translate(0);
      height: auto;
      margin-top: 16px;
    }
  }
}
