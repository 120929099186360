.about-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.about-card {
  background: #f3f3f5;
  border: 1px solid #0a0a0b;
  border-radius: 16px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 330px;
  position: relative;
  padding: 32px 40px;
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
  }
  &:nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &.col-3 {
    width: calc(42% - 12px);
  }
  &.col-4 {
    width: calc(58% - 12px);
  }
  &.col-5 {
    width: calc(70% - 12px);
    .about-card {
      &__title {
        max-width: 440px;
      }
    }
  }
  &.col-2 {
    width: calc(30% - 12px);
  }

  &__title {
    font-weight: 600;
    font-size: 26px;
    line-height: 145%;
    letter-spacing: 0.01em;
    color: #0a0a0b;
    margin-bottom: 32px;
    span {
      background: #f4e27f;
      border-radius: 40px;
      padding: 0 16px;
    }
  }
  &__content {
    display: flex;
    gap: 24px;
    p {
      font-size: 18px;
      line-height: 160%;
      letter-spacing: 0.01em;
      color: #0a0a0b;
    }
  }
  &__label-title {
    background: #ffffff;
    border: 1px solid #0a0a0b;
    border-radius: 100px;
    display: inline-flex;
    align-items: center;
    padding: 8px 16px 8px 10px;
    font-weight: 600;
    font-size: 26px;
    letter-spacing: 0.01em;
    color: #0a0a0b;
    gap: 16px;
    margin-bottom: 32px;
  }
  &__items {
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: center;
    clear: both;
    margin-top: 16px;
  }
  &__out-img {
    text-align: right;
    img {
      max-width: unset;
      float: right;
    }
  }
}

@media (max-width: 1200px) {
  .about-card {
    min-height: 275px;
    &:nth-child(2) {
      img {
        max-width: 45%;
      }
    }
    &__title {
      font-size: 24px;
      margin-bottom: 16px;
    }
    &__content {
      p {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 991px) {
  .about-card {
    &:nth-child(1).col-3 {
      width: 100%;
    }
    &:nth-child(2).col-4 {
      width: 100%;
    }
    &:nth-child(3).col-5 {
      width: 100%;
    }
    &.col-5 {
      width: calc(60% - 12px);
    }
    &.col-2 {
      width: calc(40% - 12px);
    }
    &.col-4 {
      width: calc(60% - 12px);
    }
    &:nth-last-child(4) {
      width: calc(60% - 12px);
    }
    &:last-child.col-5 {
      width: 100%;
    }
    &__out-img {
      text-align: center;
      img {
        float: none;
      }
    }
  }
}

@media (max-width: 767px) {
  .about-cards {
    gap: 12px;
  }
  .about-card {
    padding: 24px;
    min-height: auto;
    &.with-bg {
      padding-bottom: 70%;
    }
    &:nth-child(1).col-3 {
      width: 100%;
    }
    &:nth-child(2).col-4 {
      width: 100%;
      img {
        max-width: 100%;
      }
    }
    &:nth-child(3).col-5 {
      width: 100%;
    }
    &.col-5 {
      width: 100%;
    }
    &.col-2 {
      width: 100%;
    }
    &.col-4 {
      width: 100%;
    }
    &:nth-last-child(4) {
      width: 100%;
    }
    &:last-child.col-5 {
      width: 100%;
    }
    &__out-img {
      img {
        width: 130%;
        margin-left: -15%;
      }
    }
    &__title {
      font-size: 20px;
      margin-bottom: 12px;
    }
    &__content {
      gap: 16px;
      flex-direction: column;
      img {
        max-width: 100%;
      }
    }
    &__label-title {
      font-size: 20px;
      margin-bottom: 16px;
    }
    &__items {
      gap: 16px;

      img {
        height: 46px;
      }
    }
  }
}
