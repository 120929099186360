.focus {
  margin-top: 72px;
  &__inner {
    display: flex;
    justify-content: space-between;
  }
  &__header {
    max-width: 710px;
    position: relative;
    padding-top: 55px;
  }
  &__label {
    position: absolute;
    top: 62px;
    left: 100%;
    width: max-content;
    background: #c9fec4;
    border: 1px solid #0a0a0b;
    border-radius: 40px;
    padding: 8px 16px 8px 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    font-size: 22.8992px;
    line-height: 150.5%;
    text-transform: uppercase;
    color: #0a0a0b;
  }
  &__title {
    margin-bottom: 40px;
  }
  &__subtitle {
    font-family: 'Captura Now';
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    text-transform: uppercase;
    color: #0a0a0b;
    max-width: 560px;
  }
  &__stats {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    max-width: 470px;
  }
  &__stats-item {
    border: 1px solid #0f1422;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    &.lg {
      width: 212px;
      height: 212px;
    }
    &.md {
      width: 184px;
      height: 184px;
      margin-left: auto;
      margin-top: -92px;
      .focus {
        &__stats-item-text {
          font-size: 11px;
        }
      }
    }
    &.sm {
      position: absolute;
      top: -72px;
      right: 76px;
      width: 152px;
      height: 152px;
      background: #f4e27f;
      border-color: #f4e27f;
    }
  }
  &__stats-item-title {
    font-weight: 500;
    font-size: 32px;
    line-height: 190%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #0f1422;
  }
  &__stats-item-text {
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #0a0a0b;
  }
  &__stats-item-subtitle {
    font-weight: 500;
    font-size: 11px;
    line-height: 120%;
    color: #0f1421;
  }
}

@media (max-width: 1200px) {
  .focus {
    &__stats-item {
      &.sm {
        right: 32px;
      }
    }
  }
}

@media (max-width: 1199px) {
  .focus {
    margin-top: 0;
    &__inner {
      flex-wrap: wrap;
    }
    &__title {
      max-width: 560px;
    }
    &__stats {
      width: 100%;
      margin-left: auto;
      margin-top: -86px;
    }
  }
}

@media (max-width: 991px) {
  .focus {
    &__header {
      padding-top: 0;
    }
    &__title {
      margin-bottom: 24px;
      margin-right: 16px;
    }
    &__subtitle {
      font-size: 20px;
      max-width: 400px;
    }
    &__stats {
      margin-top: 0;
    }
    &__label {
      top: 0px;
      right: -40px;
    }
  }
}

@media (max-width: 767px) {
  .focus {
    &__label {
      position: relative;
      right: 0;
      left: 0;
      display: inline-flex;
      margin-bottom: 16px;
      height: 48px;
      font-size: 18px;
      img {
        width: 30px;
      }
    }
    &__title {
      margin-right: 0;
    }
    &__subtitle {
      font-size: 18px;
    }
    &__stats {
      max-width: 340px;
      margin: 80px auto 0;
    }
  }
}
