.footer {
  &__list {
    display: flex;
    justify-content: space-between;
    li {
      mix-blend-mode: multiply;
      opacity: 0.21;
    }
  }
}

@media (max-width: 991px) {
  .footer {
    &__list {
      gap: 40px 60px;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
