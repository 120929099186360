.contacts {
  &__title {
    max-width: 925px;
    margin: 0 auto 40px;
    text-align: center;
  }
  &__subtitle {
    font-family: "Captura Now";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    text-transform: uppercase;
    color: #0a0a0b;
    margin-bottom: 56px;
  }
  &__btns {
    display: flex;
    justify-content: center;
    gap: 40px;
  }
  .btn--border {
    background: #ffffff;
    &:hover {
      background: #f4e27f;
      color: var(--color-black);
    }
  }
}

@media (max-width: 991px) {
  .contacts {
    &__title {
      margin-bottom: 32px;
    }
    &__subtitle {
      font-size: 20px;
      max-width: 600px;
      margin: 0 auto 32px;
    }
  }
}

@media (max-width: 767px) {
  .contacts {
    &__subtitle {
      font-size: 18px;
    }
    &__btns {
      flex-direction: column;
      gap: 16px;
      max-width: 400px;
      margin: 0 auto;
      .btn {
        width: 100%;
      }
    }
  }
}
