.target {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 48px;
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }
  &__item {
    background: #ffffff;
    border: 1px solid #0a0a0b;
    border-radius: 16px;
    padding: 32px 40px;
    min-height: 416px;
    display: flex;
    flex-direction: column;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    &.end {
      justify-content: flex-end;
    }
    &:nth-child(1) {
      background-image: url(../../img/target/target-card-bg-1.jpg);
    }
    &:nth-child(2) {
      background-image: url(../../img/target/target-card-bg-2.jpg);
    }
    &:nth-child(3) {
      background-image: url(../../img/target/target-card-bg-7.png);
    }
    &:nth-child(4) {
      background-image: url(../../img/target/target-card-bg-4.jpg);
    }
    &:nth-child(5) {
      background-image: url(../../img/target/target-card-bg-5.jpg);
    }
    &:nth-child(6) {
      background-image: url(../../img/target/target-card-bg-6.jpg);
    }
  }
  &__number {
    font-weight: 700;
    font-size: 66.3464px;
    line-height: 100%;
    letter-spacing: -2.55178px;
    color: #0a0a0b;
    margin-bottom: 24px;
  }
  &__title {
    font-weight: 600;
    font-size: 30px;
    line-height: 100%;
    letter-spacing: -2px;
    color: #0a0a0b;
    li:not(:last-child) {
      margin-bottom: 8px;
    }
    &.center {
      text-align: center;
    }
    span {
      display: inline-block;
      padding: 0 12px;
      border-radius: 28px;
      margin-left: -12px;
      height: 38px;
      line-height: 38px;
      &.black {
        background-color: var(--color-black);
        color: #fff;
      }
      &.purpure {
        background: #ccbaff;
      }
      &.green {
        background: #c9fec4;
      }
      &.yellow {
        background: #f4e27f;
      }
    }
  }
}

@media (max-width: 1200px) {
  .target {
    &__item {
      min-height: 370px;
    }
    &__number {
      font-size: 50px;
      margin-bottom: 16px;
    }
    &__title {
      font-size: 24px;
      li:not(:last-child) {
        margin-bottom: 4px;
      }
    }
  }
}

@media (max-width: 1199px) {
  .target {
    &__item {
      min-height: 300px;
      padding: 24px;
    }
    &__title {
      font-size: 22px;
    }
  }
}

@media (max-width: 991px) {
  .target {
    &__header {
      margin-bottom: 32px;
    }
    &__list {
      display: flex;
      overflow-y: auto;
      margin-left: -24px;
      margin-right: -24px;
      padding-left: 24px;
      padding-right: 24px;
    }
    &__item {
      min-width: 280px;
    }
  }
}

@media (min-width: 768px) {
  .target {
    &__footer {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .target {
    &__list {
      gap: 12px;
    }
    &__header {
      .btn {
        display: none;
      }
    }
    &__footer {
      .btn {
        width: 100%;
        margin-top: 24px;
      }
    }
  }
}
