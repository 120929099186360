.header {
  padding-top: 40px;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
  }
  &__btn {
    height: 36px;
    display: inline-flex;
    align-items: center;
    border: 1px solid #0a0a0b;
    border-radius: 18px;
    padding: 0 10px;
    font-weight: 500;
    font-size: 16px;
    transition: 0.3s;
    img {
      margin-left: 8px;
    }
    &:hover {
      background: #ccbaff;
      border-color: #ccbaff;
    }
  }
  &__nav {
    display: flex;
    align-items: center;
    gap: 24px;
    a {
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      color: var(--color-black);
      transition: 0.3s;
      display: inline-block;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        border-bottom: 1px solid var(--color-black);
        transition: 0.3s;
      }
      &:hover {
        color: var(--color-black);
        &::before {
          width: 100%;
        }
      }
    }
  }
  &__langs {
    > li {
      position: relative;
      &:hover {
        .sub-menu {
          opacity: 1;
          pointer-events: all;
        }
        > a {
          &::after {
            transform: rotateX(180deg);
          }
        }
      }
      > a {
        display: flex;
        align-items: center;
        &::after {
          content: "";
          width: 8px;
          height: 4px;
          flex-shrink: 0;
          background-image: url(../../img/header/down-arrow.svg);
          background-repeat: no-repeat;
          background-size: contain;
          margin-left: 5px;
          transition: 0.3s;
        }
      }
    }
    a {
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      color: #0a0a0b;
      opacity: 0.8;
      transition: 0.3s;
      display: block;
    }
    .sub-menu {
      position: absolute;
      top: 100%;
      opacity: 0;
      transition: 0.3s;
      pointer-events: none;
    }
  }
}

.header__menu-btn {
  width: 30px;
  height: 19px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}

.header__menu-btn span,
#nav-icon3 span,
#nav-icon4 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: var(--color-black);
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.header__menu-btn.open span {
  background-color: #fff;
}

.header__menu-btn span:nth-child(1) {
  top: 0px;
}

.header__menu-btn span:nth-child(2) {
  top: 10px;
}

.header__menu-btn span:nth-child(3) {
  top: 19px;
}

.header__menu-btn.open span:nth-child(1) {
  top: 8px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.header__menu-btn.open span:nth-child(2) {
  opacity: 0;
  left: 0px;
}

.header__menu-btn.open span:nth-child(3) {
  top: 8px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

@media (min-width: 1200px) {
  .header {
    &__menu-btn {
      display: none;
    }
  }
}

@media (max-width: 1200px) {
  .header {
    &__logo {
      margin-right: auto;
    }
    &__nav {
      display: none;
    }
  }
}

@media (max-width: 991px) {
  .header {
    padding-top: 24px;
    &__btn {
      display: none;
    }
    .btn {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  .header {
    &__logo {
      display: block;
      width: 126px;
      img {
        display: block;
      }
    }
  }
}
