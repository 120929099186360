.promo {
  padding-top: 72px;
  &__title {
    font-family: 'Captura Now';
    font-style: normal;
    font-weight: 500;
    font-size: 49px;
    line-height: 150%;
    text-transform: uppercase;
    color: #0a0a0b;
    margin-bottom: 46px;
  }
  &__title-row {
    display: flex;
    align-items: center;
    svg,
    img {
      margin: 0 0.25em;
    }
  }
  &__body {
    position: relative;
  }
  &__block {
    max-width: 340px;
    position: absolute;
    top: 0;
    left: calc(100% / 3 * 2 + 16px);
  }
  &__block-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 34px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: #0a0a0b;
    margin-bottom: 32px;
  }
  &__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    align-items: flex-end;
  }
  &__card {
    min-height: 350px;
    border: 1px solid #0a0a0b;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    padding: 32px 40px;
    position: relative;
    &:nth-child(1) {
      &::after {
        content: '';
        position: absolute;
        top: 32px;
        right: 40px;
        width: 22px;
        height: 16px;
        background-image: url(../../img/promo/card-el-1.svg);
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    &:nth-child(2) {
      &::after {
        content: '';
        position: absolute;
        top: 32px;
        right: 40px;
        width: 16px;
        height: 16px;
        background-image: url(../../img/promo/card-el-2.svg);
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    &:nth-child(3) {
      grid-column-start: 1;
      grid-column-end: 3;
      min-height: 220px;
      max-height: 220px;
    }
    &:last-child {
      margin-top: -132px;
      background-image: url(../../img/promo/card-bg.jpg);
      background-repeat: no-repeat;
      background-position: bottom right;
      background-size: cover;
    }
    &.white {
      background-color: #fff;
    }
    &.start {
      background-color: #fff;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: -14px;
        left: -14px;
        background: #f4e27f;
        border: 1px solid #0a0a0b;
        border-radius: 16px;
        z-index: -1;
      }
    }
  }
  &__card-inner {
    background-color: #fff;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    display: flex;
    align-items: flex-end;
    margin: auto;
    svg {
      flex-shrink: 0;
      margin-left: 22px;
    }
  }
  &__card-desc {
    font-weight: 500;
    font-size: 21px;
    line-height: 145%;
    letter-spacing: 0.01em;
    color: #0a0a0b;
    padding-top: 40px;
  }
  &__card-title {
    margin-top: 20px;
    font-weight: 700;
    font-size: 22px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: #0a0a0b;
    margin-bottom: 16px;
  }
  &__card-text {
    font-size: 21px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: #0a0a0b;
  }
  &__card-bit-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 190%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: #0a0a0b;
  }
  &__card-label {
    border: 1px solid rgba(10, 10, 11, 0.41);
    width: 150px;
    height: 50px;
    text-align: center;
    line-height: 48px;
  }
  &__card-footer {
    display: flex;
    align-items: flex-end;
    gap: 26px;
    margin-top: 32px;
  }
  &__card-link {
    max-width: 128px;
    font-weight: 400;
    font-size: 13.0403px;
    line-height: 111.19%;
    text-transform: uppercase;
    color: #0a0a0b;
    margin-bottom: 5px;
    padding-bottom: 3px;
    border-bottom: 1px solid var(--color-black);
    flex-shrink: 0;
  }
  &__card-cit {
    font-weight: 400;
    font-size: 30px;
    line-height: 123%;
    text-transform: uppercase;
    color: #0a0a0b;
    opacity: 0.8;
    margin-bottom: 40px;
    margin-top: auto;
  }
  &__card-cit-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 123.5%;
    text-transform: uppercase;
    color: #0a0a0b;
    opacity: 0.8;
    margin-bottom: auto;
  }
}

@media (max-width: 1200px) {
  .promo {
    &__title {
      font-size: 54px;
    }
    &__card-bit-title {
      font-size: 20px;
    }
    &__card-desc {
      font-size: 18px;
    }
    &__card-text {
      font-size: 18px;
    }
    &__card-footer {
      img {
        width: 120px;
      }
    }
    &__block-title {
      font-size: 28px;
    }
    &__card-cit {
      font-size: 24px;
    }
  }
}

@media (max-width: 1199px) {
  .promo {
    &__title {
      font-size: 44px;
    }
    &__card {
      padding: 24px 32px;
      &:nth-child(1) {
        &::after {
          top: 24px;
          right: 24px;
        }
      }
      &:nth-child(2) {
        &::after {
          top: 24px;
          right: 24px;
        }
      }
    }
    &__card-title {
      font-size: 18px;
    }
    &__card-text {
      font-size: 16px;
    }
    &__card-footer {
      flex-wrap: wrap;
      margin-top: 24px;
      gap: 8px;
    }
    &__card-cit {
      font-size: 18px;
      max-width: 120px;
    }
    &__card-cit-text {
      font-size: 14px;
    }
  }
}

@media (max-width: 991px) {
  .promo {
    padding-top: 40px;
    &__title {
      font-size: 32px;
    }
    &__block {
      position: relative;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      max-width: 100%;
      justify-content: space-between;
    }
    &__block-title {
      max-width: 340px;
    }
    &__cards {
      display: flex;
      overflow-x: auto;
      margin-left: -24px;
      margin-right: -24px;
      padding-left: 24px;
      padding-right: 24px;
    }
    &__card {
      width: 280px;
      flex-shrink: 0;
      padding: 24px;
      &:nth-child(3) {
        max-height: 100%;
        min-height: 350px;
      }
      &.start {
        &::before {
          display: none;
        }
      }
    }
    &__card-bit-title {
      font-size: 18px;
      line-height: 150%;
      margin-bottom: 20px;
    }
    &__card-inner {
      display: block;
    }
  }
}

@media (max-width: 767px) {
  .promo {
    &__title {
      font-size: 20px;
      margin-bottom: 24px;
      img {
        display: none;
      }
    }
    &__title-row {
      &:first-child {
        flex-wrap: wrap;
        svg {
          width: 40px;
          height: auto;
        }
      }
      &:last-child {
        display: block;
        span {
          &:last-child {
            margin-left: 0.25rem;
          }
        }
      }
    }
    &__cards {
      gap: 12px;
    }
    &__card {
      min-height: 280px;
      &.start {
        min-height: 280px;
      }
    }
    &__card-desc {
      font-size: 16px;
    }
    &__card-title {
      font-size: 16px;
      margin-bottom: 8px;
    }
    &__card-text {
      font-size: 14px;
    }
    &__card-footer {
      margin-top: 8px;
    }
    &__card-bit-title {
      font-size: 16px;
    }
    &__block {
      display: block;
      margin-bottom: 40px;
      .btn {
        width: 100%;
      }
    }
    &__block-title {
      font-size: 18px;
      max-width: 290px;
      margin-bottom: 8px;
    }
  }
}
