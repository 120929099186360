.type-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
}

.type-card {
  background: #f6f6f6;
  border: 1px solid #0a0a0b;
  border-radius: 16px;
  padding: 32px 24px 32px 32px;
  &.big {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 4;
    .type-card__title {
      text-align: center;
    }
  }
  &.white {
    background-color: #fff;
  }
  &__title {
    font-weight: 600;
    font-size: 30px;
    line-height: 100%;
    letter-spacing: -2px;
    color: #000000;
    margin-bottom: 16px;
  }
  &__subtitle {
    font-size: 21px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: #000000;
  }
  &__text {
    margin-top: 16px;
    font-size: 14px;
    line-height: 145.5%;
    letter-spacing: 0.01em;
    color: #000000;
    max-width: 200px;
  }
  &__notice {
    margin: 0 24px;
    background: #f3f3f5;
    border: 1px solid #0a0a0b;
    border-radius: 16px;
    padding: 16px 24px;
  }
  &__notice-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 145.5%;
    letter-spacing: 0.01em;
    color: #000000;
    margin-bottom: 12px;
  }
  &__notice-text {
    font-size: 14px;
    line-height: 140%;
    text-transform: uppercase;
    color: #0a0a0b;
  }
  &__img {
    margin: 32px 0;
    text-align: center;
    img {
      max-width: 100%;
    }
  }
}

@media (max-width: 1200px) {
  .type-card {
    &__subtitle {
      font-size: 18px;
    }
    &__img {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media (max-width: 1199px) {
  .type-card {
    &__title {
      font-size: 24px;
    }
    &__subtitle {
      font-size: 16px;
    }
    &__img {
      width: 100%;
    }
  }
}

@media (max-width: 991px) {
  .type-cards {
    grid-template-columns: repeat(2, 1fr);
  }
  .type-card {
    &.big {
      grid-row-start: 1;
      grid-row-end: none;
      grid-column-start: 1;
      grid-column-end: -1;
    }
    &__title {
      font-size: 28px;
    }
    &__subtitle {
      font-size: 18px;
    }
  }
}

@media (max-width: 767px) {
  .type-cards {
    grid-template-columns: 1fr;
  }
  .type-card {
    padding: 24px;
    &__notice {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
