.way {
  &__header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 45px;
    margin-bottom: 56px;
  }
  &__subtitle {
    font-family: "Captura Now";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    text-transform: uppercase;
    color: #0a0a0b;
    white-space: nowrap;
    margin-bottom: 10px;
  }
  &__list {
    gap: 56px;
    display: flex;
    flex-direction: column;
  }
  &__item {
    &:nth-child(2n) {
      .way-card {
        flex-direction: row-reverse;
        &__label {
          right: auto;
          left: 32px;
        }
      }
    }
  }
}

.way-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  &__content {
    width: 460px;
    flex-shrink: 0;
  }
  &__title {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 26px;
    line-height: 145%;
    letter-spacing: 0.01em;
    color: #201a2d;
    margin-bottom: 24px;
    gap: 32px;
  }
  &__subtitle {
    font-weight: 500;
    font-size: 21px;
    line-height: 145%;
    letter-spacing: 0.01em;
    color: #0a0a0b;
    max-width: 285px;
  }

  &__text {
    font-size: 18px;
    line-height: 160%;
    letter-spacing: 0.01em;
  }
  &__list {
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    max-width: 442px;
  }
  &__list-item {
    background: #f6f6f6;
    border: 1px solid rgba(#0a0a0b, 0.12);
    border-radius: 100px;
    padding: 5px 16px;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: #0a0a0b;

    &.blue {
      background: #176be4;
      border: 1px solid rgba(10, 10, 11, 0.2);
      color: #fff;
    }
    &.yellow {
      background: #f4e27f;
      border: 1px solid rgba(10, 10, 11, 0.2);
    }
  }
  &__img {
    position: relative;
  }
  &__label {
    position: absolute;
    right: 32px;
    bottom: 24px;
    background: rgba(255, 255, 255, 0.75);
    border-radius: 10px;
    padding: 8px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: rgba(10, 10, 11, 0.7);
  }
}

@media (max-width: 1200px) {
  .way {
    &__subtitle {
      white-space: normal;
    }
  }
}

@media (max-width: 1199px) {
  .way {
    &__subtitle {
      font-size: 20px;
    }
  }
}

@media (max-width: 991px) {
  .way {
    &__header {
      flex-wrap: wrap;
      gap: 8px;
      margin-bottom: 40px;
    }
    &__list {
      gap: 40px;
    }
  }
  .way-card {
    display: block;
    &__content {
      width: 100%;
      margin-bottom: 32px;
    }
    &__list {
      max-width: 100%;
    }
  }
}

@media (max-width: 767px) {
  .way {
    &__subtitle {
      font-size: 18px;
    }
  }
  .way-card {
    &__title {
      font-size: 20px;
    }
    &__subtitle {
      font-size: 16px;
    }
    &__text {
      font-size: 16px;
    }
    &__list {
      margin-top: 24px;
      gap: 6px;
    }
    &__list-item {
      font-size: 12px;
    }
    &__label {
      left: 16px !important;
      bottom: 16px;
    }
  }
}
