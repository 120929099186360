html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  overflow-anchor: none;
  font-family: "Montserrat", sans-serif;
  background: #f6f6f6;
  scroll-behavior: smooth;
  color: var(--color-black);
}

html {
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.1);
  }
}

.wrapper,
main {
  overflow: hidden;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

.container {
  padding: 0 24px;
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
}

.pt-lg {
  padding-top: 100px;
}

.pb-lg {
  padding-bottom: 100px;
}

h2 {
  font-family: "Captura Now";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 150%;
  text-transform: uppercase;
  color: var(--color-black);
}

.btn {
  border-radius: 12px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  transition: 0.3s;
  img {
    margin-right: 12px;
  }
  &--xl {
    width: 212px;
  }
  &--lg {
    width: 200px;
  }
  &--border {
    border: 1px solid #0a0a0b;
    &:hover {
      background: #0a0a0b;
      color: #fff;
    }
  }
  &--yellow {
    background: #f4e27f;
    border: 1px solid #0a0a0b;
    &:hover {
      background-color: #fff;
    }
  }
  &--black {
    color: #fff;
    background-color: var(--color-black);
    &:hover {
      color: var(--color-black);
      background-color: #fff;
    }
  }
}

.link {
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: rgba(#000000, 0.7);
  transition: 0.3s;
  display: inline-flex;
  align-items: center;
  position: relative;
  padding-bottom: 6px;
  svg {
    margin-left: 8px;
  }
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 2px solid #464646;
    transition: 0.3s;
  }
  &:hover {
    &::before {
      width: 0;
    }
  }
}

@media (max-width: 1200px) {
  .container {
    max-width: 1152px;
  }
}

@media (max-width: 1199px) {
  .container {
    max-width: 970px;
  }
  .pt-lg {
    padding-top: 80px;
  }
  .pb-lg {
    padding-bottom: 80px;
  }
}

@media (max-width: 991px) {
  .container {
    max-width: 100%;
  }
  h2 {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .container {
    padding: 0 20px;
  }
  .pb-lg {
    padding-top: 50px;
  }
  .pb-lg {
    padding-bottom: 50px;
  }
  h2 {
    font-size: 28px;
  }
}
