.account-details {
  &__container {
    width: 100%;
    height: 100%;
    border: 1px solid #0a0a0b;
    border-radius: 12px;
    padding: 50px 36px 50px 36px;
    background: #f3f3f5;
  }
  &__header {
    // margin: auto;
    // display: flex;
    align-items: center;
    // justify-content: space-between;
    margin-bottom: 56px;
    // margin: 0 auto 40px;
    text-align: center;
  }
  &__title-row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
  &__row {
    max-width: 1030px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    margin: 0 auto;
  }
  &__panel {
    background: #ffffff;
    border: 1px solid #0a0a0b;
    border-radius: 16px;
    padding: 32px 60px 32px;
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
    &.h100 {
      height: 100%;
    }
  }
  &__panel-title {
    margin-top: 8px;
    font-weight: 600;
    font-size: 27.7255px;
    line-height: 100%;
    letter-spacing: -1.44404px;
    color: #0a0a0b;
    text-align: center;
    margin-bottom: 32px;
  }
  &__items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }
  &__item {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
    &.accent {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .account-details {
        &__item-label {
          font-weight: 600;
          font-size: 23px;
          line-height: 100%;
          letter-spacing: -1.3324px;
          color: #0a0a0b;
        }
        &__item-value {
          font-weight: 600;
          font-size: 16px;
          line-height: 130%;
          color: #0a0a0b;
        }
      }
    }
  }
  &__item-header {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__item-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: -1.44404px;
    color: #0a0a0b;
  }
  &__item-value {
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    color: #0a0a0b;
  }
}

.copy-button {
  background: #f4e27f;
  border: 1px solid #0a0a0b;
  border-radius: 42.8571px;
  height: 24px;
  line-height: 22px;
  width: 54px;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: -0.290796px;
  color: #0a0a0b;
  cursor: pointer;
  transition: 0.3s;
  display: inline-block;
  text-transform: uppercase;
  &:hover {
    background: #d8b90c;
  }
}

@media (max-width: 1199px) {
  .account-details {
    &__header {
      margin-bottom: 40px;
    }
    &__panel {
      padding-left: 19px;
      padding-right: 19px;
    }
  }
}

@media (max-width: 991px) {
  .account-details {
    &__row {
      grid-template-columns: 1fr;
      gap: 24px;
    }
  }
}

@media (max-width: 767px) {
  .account-details {
    &__container {
      margin-left: -20px;
      margin-right: -20px;
      padding: 0px 20px;
      width: auto;
      border-radius: 0;
      border: none;
    }
    &__header {
      margin-bottom: 24px;
    }
    &__title-row {
      svg {
        width: 50px;
      }
    }
    &__item {
      &.accent {
        flex-wrap: wrap;
        gap: 12px;
      }
    }
    &__row {
      gap: 16px;
    }
    &__panel {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
    &__panel-title {
      font-size: 24px;
      margin-top: 0;
    }
    &__item {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      &.accent {
        .account-details {
          &__item-label {
            font-size: 20px;
          }
        }
      }
    }
    &__item-header {
      margin-bottom: 8px;
    }
    &__item-value {
      font-size: 16px;
    }
  }
}
